import React, { useState, Fragment } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { motion } from 'framer-motion'
import { Parallax } from 'react-scroll-parallax';

import { Layout } from '../components'
import { Fade } from '../utils'

import { container, padding, bgImage, bgIcon, parallaxProps } from '../styles/global'
import { media, useBreakpoint } from '../styles/utils'
import {
    heading1,
    heading2,
    subheading2,
    heading3,
    links,
    body,
    subheading1,
    caption,
} from '../styles/type'
import {
    brown1,
    brown3,
    blue2,
    brown4,
    brown2,
    blue1,
    blue3,
    blue4,
} from '../styles/colors'

import arrow from '../assets/images/icons/arrow-blue.svg'

const renderHeroImage = data => {
    return (
        <Hero>
            <Parallax
                {...parallaxProps}
            >
                <Image
                    key={data.acf.hero_image.ID}
                    src={data.acf.hero_image.sizes && data.acf.hero_image.sizes.medium2}
                >
                    {(src, loading) => {
                        return (
                            <BGImage
                                image={src}
                                style={{ opacity: loading ? 0 : 1 }}
                            />
                        )
                    }}
                </Image>
            </Parallax>
        </Hero>
    )
}
const renderIntro = data => {
    const isPhone = useBreakpoint('phone');
    
    return (
        <Intro>
            <Fade delay={isPhone ? 0 : 1200}>
                <Container>
                    <Grid>
                        <Sidebar>
                            <Subheading>{data.title}</Subheading>
                        </Sidebar>

                        <Main>
                            <Heading>{data.title}</Heading>

                            {useBreakpoint('phone') && (
                                <MobileHeading>Our Services</MobileHeading>
                            )}
                            <Description
                                dangerouslySetInnerHTML={{
                                    __html: data.acf.intro_text,
                                }}
                            />
                        </Main>
                    </Grid>
                </Container>
            </Fade>
        </Intro>
    )
}

const renderInclusions = data => {
    const inclusions = data.acf.inclusions.map((item, i) => {
        return <InclusionsItem key={i}>{item.item}</InclusionsItem>
    })

    return <Inclusions>{inclusions}</Inclusions>
}

const renderTransactions = data => {
    const transactions = data.acf.transactions.map((transaction, i) => {
        const date = transaction.acf.date
        const sector = transaction.acf.sectors[0] ? transaction.acf.sectors[0].name : ''
        const formatted_title = transaction.acf.formatted_title
        const heading = transaction.acf.heading
        const short_description = transaction.acf.short_description

        return (
            <TransactionItem key={i} whileHover={'hover'} {...animatedPosition}>
                <TransactionInfo>
                    <Meta>
                        <Date>{date}</Date>
                        <Sector
                            dangerouslySetInnerHTML={{
                                __html: sector,
                            }}
                        />
                    </Meta>

                    <Title
                        dangerouslySetInnerHTML={{
                            __html: formatted_title,
                        }}
                    />

                    <Heading>{heading}</Heading>

                    <Description
                        dangerouslySetInnerHTML={{
                            __html: short_description,
                        }}
                    />
                </TransactionInfo>
            </TransactionItem>
        )
    })

    return (
        <Fragment>
            <Transactions>{transactions}</Transactions>


            <LinkTransition whileHover={'hover'} {...animatedLink}>            
                <TransactionsLink to={`/recent-transactions`}>
                    See All Recent Transactions
                    <Icon src={arrow} />
                </TransactionsLink>
            </LinkTransition>
        </Fragment>
    )
}

const renderRelated = data => {
    return (
        <Related>
            <Fade>
                <Container>
                    <Grid>
                        <Sidebar>
                            <Subheading>Services include</Subheading>
                            {data.acf.inclusions && renderInclusions(data)}
                        </Sidebar>

                        <Main
                            className={'recent-transactions'}
                        >
                            <Subheading>Recent transactions</Subheading>
                            {data.acf.transactions && renderTransactions(data)}
                        </Main>
                    </Grid>
                </Container>
            </Fade>
        </Related>
    )
}

const Service = ({ pageContext, ...props }) => {
    console.log('data', pageContext)

    return (
        <Layout
            meta={pageContext && pageContext.acf.seo}
            desktopHeaderTheme={'dark'}
        >
            {!useBreakpoint('phone') &&
                pageContext.acf.hero_image &&
                renderHeroImage(pageContext)}
            {pageContext && renderIntro(pageContext)}
            {pageContext && renderRelated(pageContext)}
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const MobileHeading = styled.h2``
const Subheading = styled.h2``
const Description = styled.div``
const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Grid = styled.div``
const Sidebar = styled.div``
const Main = styled.div``
const Container = styled.div`
    ${container}
    ${padding}

    ${Container} > ${Grid} {
        display: flex;
        max-width: 100%;

        ${media.phone`
            flex-direction: column;
        `}
    }

    ${Sidebar}, ${Main} {
        box-sizing: border-box;
    }

    ${Sidebar} {
        width: 33.33%;

        ${media.phone`
            width: 100%;
        `}
    }

    ${Main} {
        width: 66.66%;

        ${media.phone`
            width: 100%;
        `}
    }

    /* Border Gap */

    .recent-transactions {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            left: -1px;
            top: 40px;
            height: 20px;
            width: 1px;
            background: ${blue3};
        }
    }
`

// Animations

const animatedPosition = {
    variants: {
        hover: {
            y: '-12px',
        },
    },
    transition: { duration: 0.3 },
}

// Hero Image

const Hero = styled.div`
    position: relative;
    height: 480px;
    overflow: hidden;

    ${media.phone`
        height: 100vh;
    `}

    ${Image},
    ${BGImage} {
        height: 100%;
    }

    .parallax-outer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: black;
    }

    ${BGImage} {
        min-height: 550px;
    }

    .react-reveal {
        height: 100%;
    }
`

// Intro
const Intro = styled.div`
    margin-top: -150px;
    background-color: ${blue3};

    ${media.tabletLandscape`
        margin-top: -100px;
    `}

    ${media.phone`
        margin-top: 0;
    `}

    ${Sidebar}, ${Main} {
        padding-top: 227px;

        ${media.tabletLandscape`
            padding-top: 200px;
        `}

        ${media.phone`
            padding-top: 100px;
        `}
    }

    ${Sidebar} {
        ${Subheading} {
            ${heading3};
            color: white;
        }

        ${media.phone`
            display: none;
        `}
    }

    ${Main} {
        position: relative;
        padding-left: 20px;
        padding-bottom: 55px;
        border-left: 1px solid ${blue2};

        /* White border over image */
        &:before {
            content: '';
            position: absolute;
            left: -1px;
            top: 0;
            height: 150px;
            width: 1px;
            background: white;

            ${media.tabletLandscape`
                height: 100px;
            `}

            ${media.phone`
                display: none;
            `}
        }

        ${media.tabletLandscape`
            padding-bottom: 100px;
        `}

        ${media.phone`
            padding-bottom: 32px;
            padding-left: 0;
            border-left: 0;
        `}
    }

    ${Heading} {
        position: absolute;
        top: 0;
        left: 20px;
        ${heading1};
        color: white;
        line-height: 1;

        ${media.phone`
            position: static;
            color: ${blue1};
        `}
    }

    ${MobileHeading} {
        margin-top: 96px;
        ${heading3};
        color: white;
    }

    ${Description} {
        ${body};
        color: ${blue1};

        ${media.phone`
            margin-top: 30px;
        `}

        a {
            color: ${blue2};
            transition: color 0.15s ease;

            &:hover {
                color: ${brown2};
            }
        }
    }
`

// Related

const Related = styled.div`
    padding-bottom: 20px;
    background-color: #c4d5dc;

    ${Subheading} {
        ${subheading2};
        color: white;

        ${media.phone`
            font-size: 14px;
        `}
    }

    /* Sidebar */
    ${Sidebar} {
        padding-right: 100px;

        ${media.tabletLandscape`
            padding-right: 50px;
        `}

        ${media.phone`
            padding-right: 0;
        `}
    }

    /* Main */
    ${Main} {
        padding-left: 20px;
        padding-bottom: 100px;
        border-left: 1px solid ${blue2};

        ${media.phone`
            margin-top: 50px;
            padding-left: 0;
            padding-bottom: 32px;
            border-left: none;
        `}
    }
`

// Services

const InclusionsItem = styled.li``
const Inclusions = styled.ul`
    margin-top: 40px;

    ${media.phone`
        margin-top: 24px;
    `}

    ${InclusionsItem} {
        ${body};
        color: ${blue1};
    }
`

// Transactions

const TransactionInfo = styled.div``
const Meta = styled.div``
const Date = styled.div``
const Sector = styled.div``
const Title = styled.div``
const TransactionItem = styled(motion.div)``
const Transactions = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: 40px -10px 0;
    padding-bottom: 40px;

    ${media.tablet`
        margin-bottom: -20px;
    `}

    ${media.phone`
        display: block;
        margin: 24px 0 -20px;
        padding-bottom: 40px;
    `}

    ${TransactionItem} {
        display: inline-block;
        width: 33.333%;
        padding: 0 10px;
        box-sizing: border-box;
        margin-bottom: 20px;

        ${media.tablet`
            width: 50%;
            margin-bottom: 20px;
        `}

        ${media.phone`
            width: 100%;
            padding: 0;
        `}
    }

    ${TransactionInfo} {
        height: 100%;
        padding: 20px 20px 160px;
        box-sizing: border-box;
        background-color: ${blue4};

        ${media.tablet`
            padding-bottom: 80px;
        `}

        ${media.phone`
            padding-bottom: 40px;
        `}
    }

    ${Meta} {
        padding-bottom: 16px;
        border-bottom: 1px solid ${blue1};

        ${Date} {
            ${subheading2};
            color: ${blue1};
        }

        ${Sector} {
            margin-top: 6px;
            ${subheading1};
            color: ${blue1};
            word-break: break-word;
            hyphens: auto;
        }
    }

    ${Title} {
        margin-top: 10px;
        ${heading2};
        color: ${blue1};

        ${media.phone`
            font-size: 24px;
        `}

        p {
            margin: 0;
        }

        em {
            font-weight: 300;
            font-style: italic;
        }

        strong {
            font-weight: 600;
        }
    }

    ${Heading} {
        margin-top: 10px;
        ${subheading2};
        color: ${blue1};
    }

    ${Description} {
        margin-top: 4px;
        ${caption};
        color: ${blue1};
    }
`

/* Transactions Link */

// Animations
const animatedLink = {
    initial: {
        y: 0,
        color: '#ffffff',
        borderTopColor: '#ffffff'
    },
    variants: {
        hover: {
            y: '-6px',
            color: `${blue1}`,
            borderTopColor: `${blue1}`,
        },
    },
    transition: { duration: 0.3 },
}

const LinkTransition = styled(motion.div)`
    color: white;
    border-top: 2px solid white;
`
const Icon = styled.img``
const TransactionsLink = styled(Link)`
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
    ${subheading2};
    color: inherit;
`

export default Service
